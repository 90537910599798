import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import i18n from '@/jslib/i18n'
Vue.use(i18n)
import '@/jslib/element-ui'
import '@/jslib/vant-ui'
import '@/assets/css/common.scss'
import './jslib/components' // 自定義組件
import '@/mixins/index'
import './jslib/fontawesom' // 圖標庫
import '@/mixins/global'
// import Fastclick from 'fastclick'
Vue.config.productionTip = false

const isIpad =  ( navigator.userAgent.match(/(iPad|Mobile)/i) /* iOS pre 13 */ || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1))
// Fastclick.attach(document.body)
let vm = {
  router,
  store,
  i18n,
  mounted() {
    // 样式判断代码

  },
  render: h => h(App)
}

if(isIpad){
  import('@assets/css/ipad.scss').then(res => {
    new Vue(vm).$mount('#app')
  })
}else {
  new Vue(vm).$mount('#app')
}

