<template>
  <section
    class="header-container">
    <div class="flex-sb">
      <div class="flex-col header-left">
        <div
          v-if="step"
          class="qfilter-step  flex">
          <span
            v-for="(item,index) in total"
            :key="index + 'stepItem'"
            class="qfilter-stepItem"
            :class="[
              {
                'active' : step >= (index + 1)
              }
            ]"
          ></span>
        </div>
        <div class="flex-sb letter-spacing-2">
          <div
            class="header-title flex-col">
            <span class="lh-150 fs-20">{{meta.en}}</span>
            <span class="mg-t-4 lh-150 fs-26">{{meta.zh}}</span>
          </div>
          <span
            v-if="step"
            class="fs-18 color-dark lh-150 flex-none"> {{step}} / {{total}}</span>
        </div>
      </div>
      <v-button
        plain
        @click="handlerClick">{{btnText}}</v-button>
    </div>
  </section>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'VHeader',
  data() {
    return {
      total:5
    }
  },
  computed:{
    meta(){
      let {meta} = this.$route||{}
      return meta||{}
    },
    step(){
      let {step} = this.meta||{}
      return step||0
    },
    btnText(){
      return  this.step==1?'離開 Leave':'上一步 Back'
    }
  },
  watch:{
  },
  methods: {
    ...mapActions('quotation',['setQuoteInformationForm','setQuotationData']),

    handlerClick() {
      let {last} = this.meta||{}
      if(this.step==1){
        this.$ConfirmBox({
          message_en: 'Are sure to leave and clear data?',
          message: '是否確認離開，資料將會清空？',
          cancelBtn:'否 No',
          confirmBtn:'是 Yes',
        }).then(() => {
          // confirm
          this.setQuoteInformationForm(null)
          this.$router.push(last)
        })
          .catch(() => {
            // cancel
          })
      }else
        this.$router.push(last)
      this.$emit('click')
    },
  }
}
</script>

<style lang="scss" scoped>
.header-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 32px;
  max-width: 100%;
  @media screen and (max-width: 420px) {
    padding: 20px;
  }
  .header-left{
    width: calc(100% - 200px);
    @media screen and (max-width: 420px) {
      width: 100%;
    }
  }
  .qfilter-step{
    margin-left: -8px;
    margin-bottom: 12px;
  }
  .qfilter-stepItem{
    flex: 1;
    margin-left: 8px;
    height: 3px;
    border-radius: 3px;
    background: #9DB7F9;
    &.active{
      background:$primary;
    }
  }


  .logo-container {
    //display: flex;
    //align-items: center;
    //justify-content: space-between;

    img {
      margin-right: 15px;
      width: 26px;
    }

    span {
      font-weight: bold;
      font-size: 18px;
      line-height: 20px;
    }
  }

  .header-right {
    .user {
      color: #616266;
      font-size: 16px;
      font-weight: 500;
    }

    .el-button {
      margin-left: 10px;
    }
  }
  .header-title{
    text-align: left;
    font-weight: 500;
  }
  .flex-none{
    flex:none;
  }
}
</style>
