import axios from 'axios'
import Vue from 'vue'
import router from '@router/index'
import { Loading } from 'element-ui'
const env = require('../../env')

const instance = process.env.NODE_ENV !== 'production' ?
  axios.create({
    timeout: 180000
  }) :
  axios.create({
    timeout: 180000,
    baseURL: env.API_ROOT
  })

// let loadinginstace
// let hideLoading
// let hideMessage

instance.interceptors.request.use(function(config) {
  // let { method, params, data } = config
  // if(method == 'get' && params) {
  //   if(params.hideLoading === true) {
  //     hideLoading = true
  //   }
  //   if(params.hideMessage === true) {
  //     hideMessage = true
  //   }
  // }
  // else {
  //   if(data) {
  //     // if(data.hideLoading === true) {
  //     //   hideLoading = true
  //     // }
  //     // if(data.hideMessage === true) {
  //     //   hideMessage = true
  //     // }
  //   }
  // }

  // if(!hideLoading) {
  //   // 全局loading
  //   loadinginstace = Loading.service({ fullscreen: true })
  // }


  config.headers['Accept-Language'] = localStorage.getItem('lang') || 'en-US'
  config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')
  return config
}, function(error) {
  return Promise.reject(error)
})

instance.interceptors.response.use(function(res) {
  return res
}, function(error) {
  return Promise.reject(error)
})

const Axios = (options = {}) => {

  const { method } = options
  const optionsParams = method == 'get'? options.params: options.data

  let hideLoading = false
  if(optionsParams && optionsParams.hideLoading) {
    hideLoading = true
    delete optionsParams.hideLoading
  }

  let loading

  return new Promise((resolve, reject) => {

    if(!hideLoading) {
      loading = Loading.service({ background: 'rgba(255, 255, 255, .3)' })
    }

    instance({
      ...options
    }).then(res => {

      if(!hideLoading) {
        try {
          loading.close()
        } catch {

        }
      }

      const { data } = res
      const { message_language, message, code } = data
      if((message_language || message) && code && code !== 200) {
        Vue.prototype.$message({
          type: 'error',
          message: message_language || message
        })
        reject(res)
      }
      resolve(res)
    }).catch(err => {

      if(!hideLoading) {
        try {
          loading.close()
        } catch {

        }
      }

      const response = err.response
      const { status, data } = response
      const { message, message_language } = data || {}
      if(status === 401) {
        localStorage.removeItem('token')
        const { fullPath } = router.history.current
        // if (fullPath.indexOf('/login') === -1) {
        //   router.push({
        //     path: '/login',
        //     query: {
        //       fullPath
        //     }
        //   })
        // }
      }
      else if(status === 500) {
        Vue.prototype.$message({
          type: 'error',
          message: message_language || message
        })
      }
      else if(data) {
        if(message_language || message) {
          Vue.prototype.$message({
            type: 'error',
            message: message_language || message
          })
        }
      }


      reject(err.response)
    })
  })
}

export default Axios
