<template>
  <van-popup
    v-model="popVisible"
    position="right"
    class="cameraPop"
    get-container="body"
    :closeable="false"
  >
    <div
      class="camera_box">
      <div
        type="text"
        class="change_camera top"
        @click="handleClose"
      >
        <i class="camera_go el-icon-close"></i>
      </div>
      <div
        ref="main"
        class="scanCamera-main flex-1">
        <div class="scanCamera">
          <canvas
            ref="canvas"
            :width="canWidth"
            :height="canHeight"
            class="canvasVideo"></canvas>
          <template v-if="result.url">
            <img
              :src="result.url"
              class="scanCamera-img">
          </template>
          <div
            v-if="!result.url"
            class="scan—box position-top flex-aligin">
            <div class="flex-sb-center">
              <img
                alt=""
                src="../../assets/images/icon/Corner-1.png"/>
              <img
                alt=""
                src="../../assets/images/icon/Corner-2.png"/>
            </div>
          </div>
          <div
            class="scan—box position-bottom flex-aligin">
            <div class="flex-sb-center">
              <img
                alt=""
                src="../../assets/images/icon/Corner-3.png"/>
              <img
                alt=""
                src="../../assets/images/icon/Corner-4.png"/>
            </div>
          </div>

          <video
            ref="video"
            class="cameraVideo"
            muted
            autoplay
            playsinline></video>
        </div>
      </div>
      <!-- 拍摄操作区域 -->
      <div class="btn_boxs">
        <div class="change_camera">
          <i
            class="camera_go el-icon-refresh"
            @click='changeDevice'></i>
        </div>
        <!-- 前置后置切换 -->
        <div
          v-if="!result.url"
          class="outer-ring"
          @click="shoot"
        >

          <div class="middle-ring">
            <div class="inner-ring">

            </div>
          </div>
        </div>
        <div
          v-else
          class="outer-ring"
          @click="confirmPic"
        >
          <div class="middle-ring">
            <div class="inner-ring">
              <i
                v-if="!isUpload"
                class="el-icon-check confirm-icon"></i>
              <i
                v-else
                class="el-icon-loading confirm-icon"></i>
            </div>
          </div>
        </div>
        <div
          v-if="result.url"
          class="change_camera right">
          <i
            class="camera_go el-icon-refresh-left"
            @click='handleReTake'></i>
        </div>
      </div>
    </div>
  </van-popup>
</template>
<script >
import {uploadFile} from '@api/common'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  data(){
    return{
      startStauts:true,
      cameraStatu:0,
      video:null,
      canvas:null,
      closeStatus:true,
      canWidth:'',
      canHeight:'',
      video_stream:'',
      result:{},
      popVisible : false,
      isUpload : false,
    }
  },
  computed:{

  },
  watch:{
    value: {
      immediate: true,
      handler(val){
        if(val){
          this.handlerStart()
        }else{
          this.closeCamera()
        }
        this.popVisible = val
      }
    },
  },
  mounted() {
    // this.handlerStart()
  },
  beforeDestroy(){
    this.closeCamera()
  },
  methods: {
    handleReTake(){
      this.result = {url:''}
      this.handlerStart()
    },
    oldGetUserMedia(){
      return navigator.getUserMedia
        || navigator.webkitGetUserMedia
        || navigator.mozGetUserMedia
        || navigator.msGetUserMedia
    },
    getUserMedia(constraints){
      if ('mediaDevices' in navigator) {
        return navigator.mediaDevices.getUserMedia(constraints)
      }
      // 相容處理，依照新接口
      navigator.mediaDevices = {}

      navigator.mediaDevices.getUserMedia = function(constraints) {
        // const getUserMedia = this.oldGetUserMedia()
        const getUserMedia = navigator.getUserMedia
          || navigator.webkitGetUserMedia
          || navigator.mozGetUserMedia
          || navigator.msGetUserMedia

        // 不支援的情況下
        if (!getUserMedia) {
          return Promise.reject(new Error('getUserMedia is not implemented in this browser'))
        }

        // 保持跟原先api一樣故將其封裝成promise
        return new Promise(function(resolve, reject) {
          getUserMedia.call(navigator, constraints, resolve, reject)
        })
      }

      return navigator.mediaDevices.getUserMedia(constraints)
    },
    // 前置后置切换
    async changeDevice() {
      if (this.cameraStatu == 2) {
        this.cameraStatu = 1
      } else {
        this.cameraStatu = 2
      }

      this.mediaStream = await this.getUserMedia({
        audio: false,
        video: {
          facingMode: this.cameraStatu == 2?'user':'environment',
          width: { max: 640 },
          height: { max: 640 }
        }
      })
      this.video.srcObject = this.mediaStream
      this.video.setAttribute('playsinline', '')
      this.video.play()
      this.startStauts = false
      await new Promise(r => {
        this.video.onloadedmetadata = r
      })

      console.log(this.cameraStatu)
    },
    async init(){
      this.cameraStatu = 1
      this.mediaStream = await this.getUserMedia({
        audio: false,
        video: {
          facingMode: 'environment',
          width: { max: 640 },
          height: { max: 640 }
        }
      })
      this.video.srcObject = this.mediaStream
      this.video.setAttribute('playsinline', '')
      this.video.play()
      this.startStauts = false
      await new Promise(r => {
        this.video.onloadedmetadata = r
      })
    },
    handleResize(){
      const {clientWidth , clientHeight,scrollHeight } = this.$refs.main
      const width = clientWidth
      const height = clientHeight
      this.video.width = width
      this.video.height = height
      this.canvas.width = width
      this.canvas.height = height
      this.canWidth = width
      this.canHeight = height
    },
    handlerStart(){
      this.clearCanvas()
      this.result = {}
      this.$nextTick(async ()=>{
        this.video = this.$refs.video
        this.canvas = this.$refs.canvas
        this.handleResize()
        await this.init()
      })
    },
    clearCanvas(){
      if(!this.canvas) return
      const ctx = this.canvas.getContext('2d')
      const width = this.canvas.width
      const height = this.canvas.height
      ctx.clearRect(0, 0, width, height)
    },
    //  关闭摄像头
    closeCamera() {
      if (!this.video.srcObject) return
      let stream = this.video.srcObject
      let tracks = stream.getTracks()
      tracks.forEach(track => {
        track.stop()
      })
      this.video.srcObject = null
      this.startStauts = true
      this.closeStatus = true
    },
    async confirmPic(){
      // 将 Base64 图片数据转换为 File 对象
      if(this.isUpload) return
      let photoData = this.result.url
      const file = await this.dataURLtoFile(photoData, 'photo.jpg')
      let params = new FormData()
      params.append('file', file)
      this.network().uploadOrderFile(params)
    },
    dataURLtoFile(dataURL, fileName) {
      const arr = dataURL.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], fileName, { type: mime })
    },
    // 点击拍照
    shoot() {
      if (this.startStauts == true) {
        console.log('请打开摄像头')
      } else {
        const video = this.video
        this.canvas.width = this.canWidth
        this.canvas.height = this.canHeight
        const canvas = this.canvas
        const context = canvas.getContext('2d')

        // 将视频帧绘制到Canvas上
        context.drawImage(video, 0, 0, canvas.width, canvas.height)
        // 从Canvas中获取图像数据
        const imageData = canvas.toDataURL('image/png')
        this.result = {
          url:imageData
        }
        this.closeCamera()
      }
    },
    handleClose(){
      this.$emit('input',false)
    },
    network() {
      return {
        uploadOrderFile: async (params, result) => {
          this.isUpload = true
          const { data } = await uploadFile(params)
          // this.$emit('success',data)

          this.$emit('uploadSuccess', data,this.result.url)
          this.$emit('input',false)
          this.isUpload = false

        }
      }
    }
  }
}
</script>



<style scoped lang="scss">
.close-btn{
  position: absolute;
  left: 20px;
  top: 15px;
  z-index: 3;
  .icon-password-Cross{
    font-size: 26px;
    color: white;
  }
}
.flex-1 {
  min-width: 10px;
  min-height: 10px;
  flex: 1;
}
.close_btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  width: 0;
  height: 0;
  border-top: 16px solid #EEEEEE;
  border-left: 16px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.outer-ring {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background-color: #fff;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.middle-ring {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.inner-ring {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  .confirm-icon{
    font-size: 100px;
    font-weight: 600;

  }
}

.camera_box {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.btn_boxs {
  width: 100%;
  height: 260px;
  padding-top: 40px;
  padding-bottom: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  //position: absolute;
  z-index: 999;
  //background: #000;
  bottom: 0;
  @media screen and (max-width: 768px) {
    height: 220px;
    padding-bottom: 120px;
  }
}
.change_camera{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #4e4b4b;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 20px;
  z-index: 999;
  .camera_go {
    font-size: 36px;
    font-weight: 600;
    color: #fff;
  }
  &.top{
    top: 20px;
  }
  &.right{
    right: 20px;
    left: auto;
  }
}
.scanCamera{
  position: relative;
  background: #1A1A1C;
  .cameraVideo{
    width: 100%;
    display: block;
    object-fit: fill;
    //height: calc(100vh - 180px);
    // background: red;
  }
  .canvasVideo,.scanCamera-img{
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    // margin: auto;
  }
  .canvasVideo{
    z-index: 2;
  }
}
.scan-contain{
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  margin: auto;

}
.scan—box{
  padding-left: 50px;
  padding-right: 50px;
  width: 100%;
  position: absolute;
  &.position-top{
    top: 200px;
  }
  &.position-bottom {
    bottom: 200px;
  }
  .flex-sb-center{
    img{
      width: 58px;
    }
  }
  @media screen and (max-width: 768px) {
    &.position-top{
      top: 100px;
    }
    &.position-bottom {
      bottom: 100px;
    }
  }

}
.cameraPop{
  width: 100%;
  height: 100%;
  background: #000000;
  overflow: hidden;
}
</style>
