import Vue from 'vue'
import { mapState, mapActions } from 'vuex'
// 僅用於存放全局數據/方法
Vue.mixin({
  filters: {

  },
  data() {
    return {

    }
  },
  computed: {
    // lang() {
    //   return this.$i18n.locale
    // }
  },
  watch: {

  },
  methods: {
    changeLanguage() {
      const lang = localStorage.getItem('lang') === 'en-US' ? 'zh-HK' : 'en-US'
      localStorage.setItem('lang', lang)
      this.$i18n.locale = lang
      this.$vantLocale(lang)
    },
    scrollToDom(dom, oldDom, target) {
      let targetDom = document.body
      if(target) targetDom = target
      if(dom) {
        const { top } = dom.getBoundingClientRect()
        // const scrollY = window.scrollY  // window.pageYOffset
        const scrollY = document.documentElement.scrollTop
        let { offsetHeight } = dom
        // KYC 階段傳第二個 dom，tab 收縮需要減去第二個 dom 的元素高度
        if(oldDom) {
          offsetHeight = oldDom.offsetHeight
        }
        // debugger
        const scrollTopResult = Number(top) + Number(scrollY) - offsetHeight - 150
        this.scrollTo(Number(scrollTopResult))
      }
      else {
        this.scrollTo(0)
      }
    },
    scrollToValidDom(validList) {
      try {
        Object.keys(validList).some(key => {
          const refs = this.$refs[key]
          const dom = refs instanceof Array? this.$refs[key][0].$el: this.$refs[key].$el
          this.scrollToDom(dom)
          return true
        })
      } catch (e) {
        console.log(e)
      }
    },
    // scrollToDom(dom) {
    //   try {
    //     if(dom) {
    //       const { top } = dom.getBoundingClientRect()
    //       const scrollY = document.getElementsByClassName('scroll-content')[0].scrollTop || window.scrollY
    //       console.log('scrollY---',scrollY)
    //       console.log('top---',top)
    //       const { offsetHeight } = dom
    //       const scrollTopResult = Number(top) + Number(scrollY) - offsetHeight
    //       console.log('sc---',scrollTopResult)
    //       this.scrollTo(Number(scrollTopResult))
    //     }
    //     else {
    //       this.scrollTo(0)
    //     }
    //   } catch (e) {
    //     console.log(e)
    //   }
    // },
    scrollTo(top) {
      try {
        // window.scrollTo({
        //   top,
        //   behavior: 'smooth'
        // })
        window.scrollTo({
          top,
          behavior: 'smooth'
        })
        // const scrollContainer = document.getElementsByClassName('scroll-content')[0]
        // scrollContainer.scrollTo({
        //   top,
        //   behavior: 'smooth'
        // })
      } catch (e) {
        console.log(e)
      }
    }
  }
})
