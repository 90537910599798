import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import quoting from './quoting'
import verify from './verify'
import example from './example'

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}


const router = new VueRouter({
  routes: [
    {
      path: '/',
      name: 'index',
      component: () => import('../views/index')
    },
    {
      path: '/quoting',
      name: 'Home',
      component: () => import('../views/home'),
      children: [
        ...quoting,
      ]
    },
    {
      path: '/quoting/insure/success',
      name: 'success',
      component: () => import('@/views/quoting/success'),
      meta: {
        keepAlive: false,
      },
    },
    {
      path: '/quoting/insure/unconfirm',
      name: 'unconfrim',
      component: () => import('@/views/quoting/unconfrim'),
      meta: {
        keepAlive: false,
      },
    },
    ...verify,
    ...example
  ]
})

router.beforeEach((to, from, next) => {
  const { path, fullPath } = to
  // const token = localStorage.getItem('token')
  // if(path !== '/login' && !token) {
  //   next({ path:'/' })
  // } else {
  //   next()
  // }
  next()
})

router.afterEach(() => {
  let scrollContent = document.querySelector('.scroll-content')
  if (scrollContent) {
    scrollContent.scrollTop = 0
  }
})

export default router
