export default [
  {
    path: '/example',
    name: 'example',
    component: () => import('@/views/example/index'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/take-photo',
    name: 'takePhoto',
    component: () => import('@/views/example/tabke-photo'),
    meta: {
      keepAlive: false
    }
  }
]
