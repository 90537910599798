<template>
  <div class="home-page-container">
    <el-container class="home-container">
      <el-container class="in-container page-content-margin-sm-and-up">
        <el-header >
          <v-header
            @click="handleClick"></v-header>
        </el-header>
        <el-main>
          <div class="quoting-page flex-1 flex-col">
            <router-view></router-view>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import VHeader from './v-header'
export default {
  name: 'HomeContainer',
  components: {
    VHeader
  },
  props:{
    step: {
      type: Number,
      default: 1,
    },
    btnText: {
      type: String,
      default: 'Cancel',
    },
    title:{
      type: Object,
      default: ()=>({}),
    }
  },
  data() {
    return {}
  },
  computed: {
  },
  created() {
  },
  methods: {
    handleClick(){
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.home-page-container {
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  background:#fff;
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */
  .home-container{
    width: 100%;
    height: 100%;
  }
  .in-container {
    width: 100%;
    //height: calc(100% - 60px);
  }
  .el-header {
    padding: 0;
    z-index: 10;
    //min-height: 160px!important;
    width: 100%;
  }
  .el-main{
    padding:0
  }
  .scroll-content{
    //height: calc(100vh - 160px);
    padding-left: 32px;
    padding-right: 32px;
    -webkit-overflow-scrolling: touch;
    .quoting-page{
      padding-bottom: 100px;
      -webkit-overflow-scrolling: touch;
    }
  }

}
</style>
